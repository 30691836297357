'use client';

import { type QuestionGroup as QuestionGroupType } from '../../domain/normalizeQuestions';
import QuestionAutocompleteFromSamePool from './QuestionAutocompleteFromSamePool';
import { Flex } from 'antd';
import QuestionAutocomplete from './QuestionAutocomplete';
import QuestionSingleChoice from './QuestionSingleChoice';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionText from './QuestionText';
import QuestionSingleLine from './QuestionSingleLine';
import QuestionFillInTheBlank from './QuestionFillInTheBlank';
import { Fragment } from 'react';
import QuestionSplitDivider from '@features/backoffice/problems/ui/Question/QuestionSplitDivider';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';

type QuestionGroupProps = {
	questionGroup: QuestionGroupType;
	leadText?: string | null | undefined;
	problemSourceId?: number;
	problemsImportCandidateId?: number;
};

function QuestionGroup({
	questionGroup,
	leadText,
	problemsImportCandidateId,
	problemSourceId,
}: QuestionGroupProps) {
	const questionSplitMarkup = (questionIndex: number) => {
		if (!problemSourceId || !problemsImportCandidateId || questionIndex === 0) return null;

		return (
			<QuestionSplitDivider
				problemSourceId={problemSourceId}
				problemsImportCandidateId={problemsImportCandidateId}
				questionIndex={questionIndex}
			/>
		);
	};

	let questionsMarkup = null;
	switch (questionGroup.type) {
		case 'autocomplete_from_same_pool':
			questionsMarkup = (
				<QuestionAutocompleteFromSamePool questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		case 'autocomplete':
			questionsMarkup = (
				<QuestionAutocomplete questions={questionGroup.questions} leadText={leadText} />
			);
			break;

		case 'single_choice':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<EditableProblemRichtext
						text={leadText ? leadText + ' ' : ''}
						className="prose"
						fieldName="lead_text"
						modelParams={{ type: 'problem' }}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<Flex gap={16} vertical>
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionSingleChoice
									answerChoices={question.ordered_answers}
									questionId={question.id}
									questionIndex={questionIndex}
								/>
							</Flex>
						</Fragment>
					))}
				</Flex>
			);
			break;
		case 'multiple_choice':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<EditableProblemRichtext
						text={leadText ? leadText + ' ' : ''}
						className="prose"
						fieldName="lead_text"
						modelParams={{ type: 'problem' }}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<Flex gap={16} vertical>
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionMultipleChoice
									answerChoices={question.ordered_answers}
									questionId={question.id}
									questionIndex={questionIndex}
								/>
							</Flex>
						</Fragment>
					))}
				</Flex>
			);
			break;

		case 'simple_text':
			questionsMarkup = (
				<Flex gap={40} vertical>
					<EditableProblemRichtext
						text={leadText ? leadText + ' ' : ''}
						className="prose"
						fieldName="lead_text"
						modelParams={{ type: 'problem' }}
					/>
					{questionGroup.questions.map((question, questionIndex) => (
						<Fragment key={question.id}>
							{questionSplitMarkup(questionIndex)}
							<Flex gap={16} vertical>
								<QuestionText question={question} questionIndex={questionIndex} />
								<QuestionSingleLine
									textAnswers={question.ordered_answers}
									questionIndex={questionIndex}
								/>
							</Flex>
						</Fragment>
					))}
				</Flex>
			);
			break;

		case 'fill_in_the_blank':
			questionsMarkup = (
				<QuestionFillInTheBlank questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		default:
			break;
	}

	return questionsMarkup;
}

export default QuestionGroup;
