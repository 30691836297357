'use client';

import { FieldType938Enum, type QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { useQuestionGroupStore } from '../Question/QuestionGroupStore';
import QuestionAnswer from './QuestionAnswer';
import dynamic from 'next/dynamic';
import { useDebounceCallback } from 'usehooks-ts';
import QuestionAnswerInput from '../Question/QuestionAnswerInput';

const MathKeyboardInput = dynamic(() => import('./MathKeyboardInput'), { ssr: false });

type QuestionAnswerTextProps = {
	answer: QuestionAnswerType;
	questionIndex: number;
};

function QuestionAnswerText({ answer, questionIndex }: QuestionAnswerTextProps) {
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const onAnswerChange = useQuestionGroupStore((state) => state.onAnswerChange);
	const enteredAsnwerTexts = useQuestionGroupStore((state) => state.enteredAsnwerTexts);
	const addEnteredAnswerText = useQuestionGroupStore((state) => state.addEnteredAnswerText);
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);

	const setValue = (value: string) => {
		if (!value || !onAnswerChange) return;

		addEnteredAnswerText({ answerText: value, answerId: answer.id, questionId: answer.question });
	};

	const debouncedSetValue = useDebounceCallback(setValue, 1000);

	if (answer.field_type === FieldType938Enum.MathExpression) {
		const initialLatex = enteredAsnwerTexts.find((text) => text.answerId === answer.id)?.answerText;
		return (
			<MathKeyboardInput
				setValue={debouncedSetValue}
				initialValue={initialLatex || ''}
				disabled={isChangingAnswersDisabled}
			/>
		);
	}

	if (answer.field_type === FieldType938Enum.SingleLineText) {
		return <QuestionAnswerInput answer={answer} />;
	}

	if (answer.field_type === FieldType938Enum.MultiLineText) {
		return <QuestionAnswerInput answer={answer} multiline />;
	}

	return (
		areAnswersBeingChecked && (
			<QuestionAnswer answer={answer} answerIndex={0} questionIndex={questionIndex} />
		)
	);
}

export default QuestionAnswerText;
