'use client';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import type { Question, QuestionAnswer } from '@features/api/client';
import stripHtmlTags from '@features/webshop/shared/domain/stripHtmlTags';
import { cn } from '@features/shared/utils/utils';
import { Select, theme } from 'antd';
import { isArray, isDefined } from 'remeda';
import { useQuestionGroupStore } from './QuestionGroupStore';

type QuestionAnswerSelectProps = {
	question: Question;
	initalAnswers: Array<QuestionAnswer>;
	uniqueAnswers: Array<QuestionAnswer>;
};

function QuestionAnswerSelect({ question, initalAnswers, uniqueAnswers }: QuestionAnswerSelectProps) {
	const {
		token: { colorSuccess, colorError },
	} = theme.useToken();

	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const setSelectedAnswerChoices = useQuestionGroupStore((state) => state.setSelectedAnswerChoices);
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);
	const areCorrectAnswersBeingShown = useQuestionGroupStore((state) => state.areCorrectAnswersBeingShown);
	// const isCheckingAnswersInlineEnabled = useQuestionGroupStore(
	// 	(state) => state.isCheckingAnswersInlineEnabled
	// );
	const isChangingAnswersDisabled = useQuestionGroupStore((state) => state.isChangingAnswersDisabled);

	const currentQuestionSelectedAnswer = selectedAnswerChoices.find((choice) => {
		return choice.questionId === question.id;
	});
	const selectedAnswer = initalAnswers.find(
		(answer) => answer.id === currentQuestionSelectedAnswer?.answerId && answer.question === question.id
	);
	const correctAnswer = initalAnswers.find(
		(answer) => answer.question === question.id && answer.is_correct
	);

	const isCorrect = areAnswersBeingChecked ? selectedAnswer?.is_correct : false;
	const statusColor = isCorrect ? colorSuccess : colorError;
	const suffixIcon = isCorrect ? (
		<CheckCircleOutlined style={{ color: statusColor }} />
	) : (
		<CloseCircleOutlined style={{ color: statusColor }} />
	);

	return (
		<div
			className={cn(
				'question-text ',
				areAnswersBeingChecked &&
					'[&_.ant-select.ant-select-outlined_.ant-select-selector]:!border-inherit [&_.ant-select.ant-select-outlined_.ant-select-selector]:!text-inherit'
			)}>
			<Select
				defaultValue={
					areCorrectAnswersBeingShown ? correctAnswer?.id : currentQuestionSelectedAnswer?.answerId
				}
				value={
					areCorrectAnswersBeingShown ? correctAnswer?.id : currentQuestionSelectedAnswer?.answerId
				}
				disabled={isChangingAnswersDisabled}
				showSearch
				placeholder={areAnswersBeingChecked ? '---------' : 'Odaberi odgovor'}
				optionFilterProp="label"
				options={uniqueAnswers.map((answer) => {
					return {
						disabled: selectedAnswerChoices.some(
							(selectedAnswer) => selectedAnswer.answerId === answer.id
						),
						label: stripHtmlTags({ text: answer.display_text }),
						value: answer.id,
					};
				})}
				allowClear
				style={{
					minWidth: '184px',
					margin: '2px 8px',
					color: areAnswersBeingChecked ? statusColor : undefined,
					borderColor: areAnswersBeingChecked ? statusColor : undefined,
				}}
				popupMatchSelectWidth={false}
				onChange={(_, options) => {
					if (isChangingAnswersDisabled) return;

					setAreAnswersBeingChecked(false);
					const selectedOptions = !isArray(options) ? [options] : options;
					const selectedAnswerChoiceOptions = [
						...selectedAnswerChoices.filter((choice) => choice.questionId !== question.id),
						...selectedOptions
							.filter(isDefined)
							.map((option) => ({ questionId: question.id, answerId: option.value })),
					];

					setSelectedAnswerChoices(selectedAnswerChoiceOptions);
				}}
				status={areAnswersBeingChecked && !isCorrect ? 'error' : undefined}
				suffixIcon={areAnswersBeingChecked ? suffixIcon : null}
			/>
		</div>
	);
}

export default QuestionAnswerSelect;
