'use client';

import type { Question } from '@features/api/client';
import QuestionAnswerInput from './QuestionAnswerInput';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';

type QuestionFillInTheBlankProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionFillInTheBlank({ questions, leadText }: QuestionFillInTheBlankProps) {
	return (
		<div className="prose !max-w-[75ch] [&_.question-text]:!inline [&_.question-text_p]:!inline">
			<EditableProblemRichtext
				text={leadText ? leadText + ' ' : ''}
				className="prose"
				fieldName="lead_text"
				modelParams={{ type: 'problem' }}
			/>
			{questions.map((question, questionIndex) => {
				return (
					<>
						<EditableProblemRichtext
							text={question.text}
							fieldName="text"
							modelParams={{ type: 'question', questionIndex: questionIndex }}
						/>
						{question.ordered_answers.map((answer) => (
							<QuestionAnswerInput answer={answer} inline />
						))}
						<EditableProblemRichtext
							text={question.postanswer_text ? question.postanswer_text + ' ' : ''}
							fieldName="postanswer_text"
							modelParams={{ type: 'question', questionIndex: questionIndex }}
						/>
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</>
				);
			})}
		</div>
	);
}

export default QuestionFillInTheBlank;
