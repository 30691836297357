'use client';

import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import { useMathJaxTypeset } from '@features/shared/providers/MathJaxProvider';
import { Flex } from 'antd';
import { useRef } from 'react';
import QuestionAnswerBlocks from './QuestionAnswerBlocks';
import { choiceLabels } from '@features/backoffice/problems/ui/QuestionAnswer/choiceLabels';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';
import EditableText from '../Problem/EditableText';

type QuestionAnswerProps = {
	answer: QuestionAnswerType;
	answerIndex: number;
	questionIndex: number;
};

function QuestionAnswer({ answer, answerIndex, questionIndex }: QuestionAnswerProps) {
	const mathjaxTypesetRef = useRef<HTMLDivElement>(null);
	useMathJaxTypeset(mathjaxTypesetRef.current);

	return (
		<div ref={mathjaxTypesetRef}>
			<Flex gap={16} align="center">
				{answer.answer_type === 'answer_choice' && choiceLabels[answerIndex]}
				<Flex gap={4} align="center" wrap="wrap">
					<EditableText
						text={answer.display_prefix}
						fieldName="display_prefix"
						modelParams={{ type: 'question_answer', answerIndex, questionIndex }}
					/>
					<EditableProblemRichtext
						text={answer.display_text}
						fieldName="display_text"
						modelParams={{ type: 'question_answer', answerIndex, questionIndex }}
					/>
					<EditableText
						text={answer.display_sufix}
						fieldName="display_sufix"
						modelParams={{ type: 'question_answer', answerIndex, questionIndex }}
					/>

					<QuestionAnswerBlocks blocks={answer.blocks} type={answer.answer_type} />
				</Flex>
			</Flex>
		</div>
	);
}

export default QuestionAnswer;
