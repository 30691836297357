import { richtext } from '@features/shared/utils/richtext';
import { cn } from '@features/shared/utils/utils';
import EditableText, { type EditableProblemTextProps } from './EditableText';
import { useProblemEditableStore } from '@features/backoffice/problems-import/ProblemEditableStore';

type EditablePtoblemRichtextProps = EditableProblemTextProps;

function EditableProblemRichtext({ text, className, ...rest }: EditablePtoblemRichtextProps) {
	const isProblemEditable = useProblemEditableStore((state) => state.isProblemEditable);

	if (isProblemEditable)
		return <EditableText text={text} className={cn('question-text', className)} {...rest} />;

	return text && richtext(text, cn('question-text', className), 'span');
}

export default EditableProblemRichtext;
