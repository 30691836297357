'use client';
import 'client-only';

import { useContext } from 'react';
import { ApiClientContext } from './ApiClientContext';
import { INTERNAL__problemsImportQueries } from '@features/backoffice/problems-import/_problemsImportQuerie';

function useApiClient() {
	const {
		authApiClient,
		webshopApiClient,
		frontofficeApiClient,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		session,
	} = useContext(ApiClientContext);

	if (
		!authApiClient ||
		!webshopApiClient ||
		!frontofficeApiClient ||
		!backofficeApiClient ||
		!sharedApiClient ||
		!webhooksApiClient
	) {
		throw new Error('useApiClient must be used within ApiClientProvider');
	}

	const queries = {
		problemImports: INTERNAL__problemsImportQueries(backofficeApiClient),
	};

	return {
		authApiClient,
		webshopApiClient,
		frontofficeApiClient,
		backofficeApiClient,
		sharedApiClient,
		webhooksApiClient,
		session,
		queries,
	};
}

export default useApiClient;
